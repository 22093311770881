import BackgroundMediaDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/BackgroundMediaDataTransformerBlock';
import { styled } from '@activebrands/core-web/libs/styletron';

const HeroWrapper = styled('section', ({ $isGrid }) => ({
    width: '100%',
    height: '100vh',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
}));

const ContentWrapper = styled('section', ({ $isGrid }) => ({
    aspectRatio: 'var(--ratio-module-background-media)',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
    width: '100%',
    maxHeight: 'calc(100vh - var(--height-header))',
    height: '100%',
}));

interface BackgroundMediaModuleProps {
    isGrid?: boolean;
    isHero?: boolean;
}

const BackgroundMediaModule = ({ isGrid = false, isHero = false, ...rest }: BackgroundMediaModuleProps) => {
    if (isHero) {
        return (
            <HeroWrapper $isGrid={isGrid}>
                <BackgroundMediaDataTransformerBlock {...rest} />
            </HeroWrapper>
        );
    }

    return (
        <ContentWrapper $isGrid={isGrid}>
            <BackgroundMediaDataTransformerBlock {...rest} />
        </ContentWrapper>
    );
};

export default BackgroundMediaModule;
